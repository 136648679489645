var CameraManager;
(function($) {
  var rearLabels = ["rear", "environment", "back"];
  var iOSswitchCameraOnceFix = false;

  CameraManager = function() {
    this.zoomLevel = 0.2; //change default wider zoom max zoom out //0.6;
    this.zoomStep = 0.1;

    this.stopMirroring = false;
    this.isIOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  };

  CameraManager.prototype.checkRearCamera = function() {
    const deviceActive = this.getActiveDevice();
    // console.log("deviceActive:", deviceActive);

    // --- check if we have the rear camera selected
    this.rearCamera = false;
    if (deviceActive.label) {
      const labelToCheck = deviceActive.label.toLowerCase().trim();
      const label = rearLabels.find(o => {
        return labelToCheck.indexOf(o) > -1;
      });
      // console.log("label", label);

      if (label) {
        this.rearCamera = true;
      }
    }
  };

  CameraManager.prototype.switchCamera = function() {
    return new Promise((resolve, reject) => {
      this.addLog(
        `switchCamera called! devices: ${JSON.stringify(this.devices)}`
      );
      if (this.devices && this.devices.length > 0) {
        if (this.selectedDeviceIndex === undefined) {
          var deviceActive = this.getActiveDevice();
          // console.log('deviceActive: 39', deviceActive);

          if (deviceActive) {
            this.selectedDeviceIndex = this.devices.findIndex(o => {
              return deviceActive.deviceId
                ? o.deviceId === deviceActive.deviceId
                : o.label === deviceActive.label;
            });
            // console.log('this.selectedDeviceIndex: 47', this.selectedDeviceIndex);
            this.selectedDeviceIndex++;
          } else {
            this.selectedDeviceIndex = 0;
          }
        } else {
          this.selectedDeviceIndex++;
        }
      }

      if (this.selectedDeviceIndex >= this.devices.length) {
        this.selectedDeviceIndex = 0;
      }

      var selectedDevice;
      // console.log("this.selectedDeviceIndex: 62", this.selectedDeviceIndex);
      if (this.selectedDeviceIndex !== undefined) {
        selectedDevice = this.devices[this.selectedDeviceIndex];
        // console.log('selectedDevice: 65', selectedDevice);
        try {
          window.localStorage.setItem(
            "lastChooseCameraDetails",
            JSON.stringify(selectedDevice)
          );
        } catch (e) {}
      }

      this.addLog(
        `switchCamera selected device: ${JSON.stringify(selectedDevice)}`
      );
      this.stopCamera()
        .then(
          () => {
            // console.log("stop camera success")
            this.startCamera(this.options)
              .then(
                () => {
                  // console.log("start camera success")
                  resolve(selectedDevice);
                },
                err => {
                  console.log("err: start camera: 73 ", err);
                  reject(err);
                }
              )
              .catch(e => {
                console.log("e: start camera catch: 77", e);
                reject(e);
              });
          },
          err => {
            console.log("err: stop camera error: 81", error);
            reject(err);
          }
        )
        .catch(e => {
          console.log("e: stop camera catch: 84", e);
          reject(e);
        });
    });
  };

  CameraManager.prototype.selectCamera = function(device) {
    if (!this.devices || this.devices.length === 0) return;

    this.selectedDeviceIndex = this.devices.findIndex(o => {
      return o.deviceId === device.deviceId;
    });
  };

  CameraManager.prototype.getDevices = function(mediaDevices) {
    var count = 1;
    this.devices = [];
    mediaDevices.forEach(mediaDevice => {
      if (mediaDevice.kind === "videoinput") {
        this.devices.push({
          deviceId: mediaDevice.deviceId,
          label: mediaDevice.label || `Camera ${count++}`
        });
      }
    });
    // console.log("this.devices", this.devices);
  };

  CameraManager.prototype.zoomIn = function() {
    this.zoomLevel += this.zoomStep;

    if (this.chromeAndroid && this.capabilities.zoom) {
      if (this.zoomLevel >= this.zoomMax) this.zoomLevel = this.zoomMax;
      this.track.applyConstraints({ advanced: [{ zoom: this.zoomLevel }] });
    } else {
      if (this.zoomLevel >= 1.0) this.zoomLevel = 1.0;
    }
  };

  CameraManager.prototype.zoomOut = function() {
    this.zoomLevel -= this.zoomStep;

    if (this.chromeAndroid && this.capabilities.zoom) {
      if (this.zoomLevel <= this.zoomMin) this.zoomLevel = this.zoomMin;
      this.track.applyConstraints({ advanced: [{ zoom: this.zoomLevel }] });
    } else {
      if (this.zoomLevel <= 0.2) this.zoomLevel = 0.2;
    }
  };

  CameraManager.prototype.addLog = function(logStr) {
    if (this.logsList) this.logsList.push(logStr);
  };

  CameraManager.prototype.startCamera = function(options, onResize) {
    return new Promise((resolve, reject) => {
      try {
        this.options = options;
        // console.log('this.options: ', this.options);

        // --- if we are in chrome && android we need to avoid doing canvas operation on runtime (Chrome Android bug)
        this.capabilities = {};
        this.chromeAndroid =
          navigator.userAgent.toLowerCase().indexOf("android") > -1 &&
          /Chrome/.test(navigator.userAgent) &&
          /Google Inc/.test(navigator.vendor);

        // --- for android chrome camera issue
        this.chromeAndroid = false;

        if (onResize !== true && this.stream) {
          this.addLog("Camera already started!");
          return;
        }

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          this.addLog("Browser does not support camera!");
          return reject("Browser does not support camera.");
        }

        // console.log('navigator: ', navigator);
        // console.log('----------------------- 159 ----------------------------');
        var localMediaStream;
        var maxCropHeight;
        var minCropHeight;

        var setupCropping = () => {
          this.videoCanvas = document.getElementById("cordova-camera-canvas");
          this.video.style.display = this.options.initialDisplay
            ? this.options.initialDisplay
            : "block";
          this.videoCanvas.style.display = this.options.initialDisplay
            ? this.options.initialDisplay
            : "block";
          this.videoCanvas.style.position = "absolute";

          this.videoCanvasContext = this.videoCanvas
            ? this.videoCanvas.getContext("2d")
            : undefined;
          if (options.crop) this.video.style.opacity = "0.0";
          else {
            if (
              this.options.startCameraObj.hasOwnProperty("isFrom") &&
              this.options.startCameraObj.isFrom == "indLookUp"
            ) {
              this.video.style.height = "25%";
              this.video.style.zIndex = 9999;
            } else if (
              !this.options.startCameraObj.hasOwnProperty("isFromGenScanner")
            ) {
              this.video.style.height = "100%";
            } else {
              this.video.style.height = "200px";
              this.video.style.zIndex = 9999;
            }
            this.video.style.position = "absolute";
          }
          let videoStyle = {
            height: this.video.style.height,
            zIndex: this.video.style.zIndex,
            position: this.video.style.position,
            display: this.video.style.display,
            opacity: this.video.style.opacity
          };
          this.addLog(
            `setupCropping: video style ${JSON.stringify(videoStyle)}`
          );

          maxCropHeight = this.video.videoHeight;
          minCropHeight = this.video.videoHeight * 0.35;
          if (minCropHeight < 390) minCropHeight = 390;

          if (options.crop) {
            options.crop = options.crop > 0 ? options.crop : 0.7;
            this.videoCanvas.width = minCropHeight * options.crop;
          }
          this.videoCanvas.height = minCropHeight;

          // --- enable/disable mirroring
          if (this.rearCamera === false) {
            this.videoCanvas.style.transform = "scaleX(-1)";
          } else {
            this.videoCanvas.style.transform = "scaleX(1)";
          }

          this.addLog(
            `setupCropping: videoCanvas width ${this.videoCanvas.width}`
          );
          this.addLog(
            `setupCropping: videoCanvas height ${this.videoCanvas.height}`
          );
        };

        var croppedSize = {
          w: 0,
          h: 0,
          x: 0,
          y: 0
        };

        var getCroppedSize = () => {
          var currenHeight =
            maxCropHeight - (maxCropHeight - minCropHeight) * this.zoomLevel;

          croppedSize.w = options.crop
            ? currenHeight * options.crop
            : currenHeight;
          croppedSize.h = currenHeight;

          croppedSize.x = this.video.videoWidth / 2 - croppedSize.w / 2;
          croppedSize.y = this.video.videoHeight / 2 - croppedSize.h / 2;
        };

        var drawOnCanvas = () => {
          this.addLog(`drawOnCanvas called!`);
          if (this.video && (this.video.paused || this.video.ended)) {
            this.addLog(`drawOnCanvas video paused/ended!`);
            return false;
          }

          // --- calculate start cropping coords
          if (this.videoCanvas) {
            getCroppedSize();
            this.addLog(
              `drawOnCanvas cropped size ${JSON.stringify(croppedSize)}`
            );

            var w = croppedSize.w;
            var h = croppedSize.h;
            var x = croppedSize.x;
            var y = croppedSize.y;

            if (x < 0 || y < 0) {
              this.videoCanvasContext.drawImage(this.video, 0, 0);
            } else {
              // --- crop video to canvas
              this.videoCanvasContext.drawImage(
                this.video,
                x,
                y,
                w,
                h,
                0,
                0,
                this.videoCanvas.width,
                this.videoCanvas.height
              );
            }
          }
          window.requestAnimationFrame(drawOnCanvas);
        };

        var setVideoSize = () => {
          this.addLog(`setVideoSize called!`);
          if (!this.video) return;
          setupCropping();
          window.setTimeout(() => {
            if (options.resize === true) {
              this.addLog(`setVideoSize resize adjustments!`);
              this.video.style.top = "0px";
              this.video.style.left = "0px";
              var aspectRatio = this.video.videoWidth / this.video.videoHeight;
              if (window.innerWidth >= window.innerHeight) {
                this.video.width = options.width;
                var newHeight = options.width / aspectRatio;
                this.video.style.top = -(newHeight - options.height) / 2 + "px";
                this.video.removeAttribute("height");
              } else {
                this.video.height = options.height;
                var newWidth = options.height * aspectRatio;
                this.video.style.left = -(newWidth - options.width) / 2 + "px";
                this.video.removeAttribute("width");
              }
            }
          });
        };

        if (!this.video) {
          this.video = document.getElementById("cordova-camera-video");
          this.addLog(`detected video element! ${this.video != null}`);
          if (options.resize === true) {
            window.onresize = () => {
              if (!this.stream) return;
              window.clearTimeout(this.resizeTimeout);
              this.resizeTimeout = window.setTimeout(() => {
                options.width = window.innerWidth;
                options.height = window.innerHeight;
                this.stopCamera().then(() => {
                  this.startCamera(this.options);
                });
              }, 300);
            };
          }
          this.video.addEventListener("playing", setVideoSize, false);
        }

        var successCallback = async stream => {
          // await new Promise(resolve => setTimeout(resolve, 1500));
          navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
            // console.log('mediaDevices: 298', mediaDevices);
            setTimeout(() => {
              this.getDevices(mediaDevices);
              this.addLog(`devices refreshed: ${JSON.stringify(this.devices)}`);
            }, 100);
          });

          this.stream = stream;
          // console.log('this.stream: 305', this.stream);

          this.addLog(`this.chromeAndroid: ${this.chromeAndroid}`);
          if (this.chromeAndroid) {
            this.track = stream.getVideoTracks()[0];
            this.capabilities = this.track.getCapabilities();
            this.hardwareSettings = this.track.getSettings();
            if (this.capabilities && this.capabilities.zoom) {
              this.zoomMax = this.capabilities.zoom.max;
              this.zoomMin = this.capabilities.zoom.min;
              this.zoomStep = this.capabilities.zoom.step * 3;
              this.zoomLevel =
                this.hardwareSettings.zoom ||
                this.zoomMin + (this.zoomMax - this.zoomMin) / 4;
              this.track.applyConstraints({
                advanced: [{ zoom: this.zoomLevel }]
              });
            }
          }

          this.video.style.display = this.options.initialDisplay
            ? this.options.initialDisplay
            : "block";
          this.addLog(`initialDisplay: ${this.video.style.display}`);
          localMediaStream = stream;
          this.video.addEventListener(
            "play",
            function() {
              if (options.crop && !this.chromeAndroid) {
                drawOnCanvas();
              }
            },
            false
          );

          if ("srcObject" in this.video) {
            this.addLog(`setting srcObject`);
            this.video.srcObject = localMediaStream;
          } else {
            this.addLog(`setting src`);
            this.video.src = window.URL.createObjectURL(localMediaStream);
          }
          this.video
            .play()
            .then(() => {
              this.addLog(`playback started`);
            })
            .catch(err => {
              this.addLog(
                `playback failed. Error: ${JSON.stringify(err, [
                  "name",
                  "message",
                  "stack"
                ])}`
              );
            });
          this.checkRearCamera();
          this.stopMirroring = false;
          // console.log("=-======================= 330 ================================")
          // --- fix for camera not starting automatically on iOS
          if (
            this.options.crop &&
            this.isIOS === true &&
            iOSswitchCameraOnceFix === false &&
            this.devices.length > 0
          ) {
            iOSswitchCameraOnceFix = true;

            this.addLog(`running some IOS fix!`);
            this.devices.forEach((device, index) => {
              window.setTimeout(() => {
                this.switchCamera();
              }, 10 * index);
            });
          }
          if (this.options.keepVideoInPlace !== true) {
            document.body.insertBefore(
              this.video.parentNode,
              document.body.firstChild
            );
          }
          // console.log("before resolve: 348 ", stream);
          resolve(stream);
        };

        navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
          this.getDevices(mediaDevices);
          this.addLog(`devices listed: ${JSON.stringify(this.devices)}`);

          // --- initially check if there is a saved device
          if (options.savedVideoDevice) {
            this.selectCamera(options.savedVideoDevice);
            options.savedVideoDevice = undefined;
          }
          this.addLog(`selected device index: ${this.selectedDeviceIndex}`);

          var variants;
          //  remove resolutions phase for entrace scanner
          if (options.diyCapture) {
            variants = {
              audio: false,
              video: {
                frameRate: { ideal: 50 },
                width: { ideal: 2000 },
                height: { ideal: 2000 }
                // width: this.chromeAndroid ? croppedSize.w : options.width,
                // height: this.chromeAndroid ? croppedSize.h : options.height
              }
            };
          } else {
            variants = {
              audio: false,
              video: {
                // width: { ideal: options.resolution ? options.resolution : 2000 },
                // height: { ideal: options.resolution ? options.resolution : 2000 }
                // width: this.chromeAndroid ? croppedSize.w : options.width,
                // height: this.chromeAndroid ? croppedSize.h : options.height
              }
            };
          }

          if (
            this.devices.length === 0 ||
            this.devices[this.selectedDeviceIndex] === undefined ||
            this.selectedDeviceIndex === undefined
          ) {
            if (options.bootCamera) {
              if (options.bootCamera === "rear") {
                variants.video.facingMode = "environment";
              } else if (options.bootCamera === "front") {
                variants.video.facingMode = "user";
              }
            } else {
              variants.video.facingMode = "environment";
            }
          } else {
            variants.video.deviceId = {
              exact: this.devices[this.selectedDeviceIndex].deviceId
            };
          }

          // console.log('variants: 418', variants);
          this.addLog(`constraints: ${JSON.stringify(variants)}`);
          navigator.mediaDevices
            .getUserMedia(variants)
            .then(successCallback.bind(this))
            .catch(error => {
              // console.log("error: getUserMedia", error)
              // Following condition for switch telephoto camera to other camera as its not supported yet
              // console.log("this.selectedDeviceIndex: 409", this.selectedDeviceIndex)
              this.addLog(
                `error fetching devices with given constraints: ${error &&
                  error.message}`
              );
              if (this.devices.length > 1 && this.selectedDeviceIndex >= 0) {
                // console.log("================= 426 =======================");
                this.switchCamera()
                  .then(
                    res => {
                      // console.log('res: switchCamera; 428', res);
                      resolve(res);
                    },
                    err => {
                      console.log("err: switchCamera: 431", err);
                      reject(err);
                    }
                  )
                  .catch(e => {
                    console.log("e: switchCamera: 434", e);
                    reject(e);
                  });
              } else {
                console.log("Camera: 439", error);
                reject(error);
              }
            });
        });
      } catch (e) {
        console.log("Error: ", e);
        this.addLog(`Error: Message: ${e && e.message}`);
        reject("Browser does not support camera.");
      }
    });
  };

  CameraManager.prototype.getActiveDevice = function() {
    var found = false;
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        var settings =
          typeof track.getSettings === "function" ? track.getSettings() : null;
        // console.log("settings", settings);

        found = {};
        if (settings && settings.deviceId) {
          found.deviceId = settings.deviceId;
        } else {
          found.label = track.label;
        }
      });
    }

    // console.log("found", found);
    if (found && found.deviceId) {
      var deviceFound = this.devices.find(o => {
        return o.deviceId === found.deviceId;
      });
      // console.log("deviceFound", deviceFound);
      if (deviceFound) {
        try {
          window.localStorage.setItem(
            "lastChooseCameraDetails",
            JSON.stringify(deviceFound)
          );
        } catch (e) {}
        return deviceFound;
      }
    }
    try {
      window.localStorage.setItem(
        "lastChooseCameraDetails",
        JSON.stringify(found)
      );
    } catch (e) {}
    return found;
  };

  CameraManager.prototype.stopCamera = function() {
    return new Promise((resolve, reject) => {
      this.addLog(`stopCamera called! has stream?: ${this.stream != null}`);
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }
      this.stream = undefined;
      if (this.video) this.video.style.display = "none";
      if (this.videoCanvas) this.videoCanvas.style.display = "none";
      this.video = this.videoCanvas = undefined;
      this.addLog(`stopCamera done!`);
      resolve();
    });
  };

  CameraManager.prototype.takePicture = function(options) {
    return new Promise(async (resolve, reject) => {
      if (!this.video || !this.stream) return reject();

      var aspectRatio = this.video.videoWidth / this.video.videoHeight;

      var finalWidth = this.video.videoWidth;
      var finalHeight = this.video.videoHeight;

      // --- decide finalWidth and finalHeight based on user input
      if (options.width) {
        finalWidth = options.width;
        finalHeight = options.width / options.crop;
      }

      var canvas = document.createElement("canvas");
      canvas.width = finalWidth;
      canvas.height = finalHeight;

      var maxCropHeight;
      var minCropHeight;
      var canvasContext;

      var setupCropping = () => {
        canvas.style.display = "block";
        canvas.style.position = "absolute";

        canvasContext = canvas ? canvas.getContext("2d") : undefined;
        if (options.crop) this.video.style.opacity = "0.0";
        else {
          this.video.style.height = "100%";
          this.video.style.position = "absolute";
        }

        this.video.width = finalWidth;
        this.video.height = finalHeight;

        maxCropHeight = this.video.videoHeight;
        minCropHeight = this.video.videoHeight * 0.35;
        if (minCropHeight < 390) minCropHeight = 390;

        var AR_S = this.video.width / this.video.height;
        var AR_D = options.targetSize.width / options.targetSize.height;
        var H, W;
        if (AR_S > AR_D) {
          H = this.video.height;
          W = H * AR_D;
        } else {
          W = this.video.width;
          H = W / AR_D;
        }

        canvas.height = H;
        canvas.width = W;

        // --- enable/disable mirroring
        if (this.rearCamera === false) {
          canvas.transform = "scaleX(-1)";
        } else {
          canvas.transform = "scaleX(1)";
        }
      };

      var croppedSize = {
        w: 0,
        h: 0,
        x: 0,
        y: 0
      };
      var getCroppedSize = () => {
        var currenHeight =
          maxCropHeight - (maxCropHeight - minCropHeight) * this.zoomLevel;

        croppedSize.w = options.crop
          ? currenHeight * options.crop
          : currenHeight;
        croppedSize.h = currenHeight;

        croppedSize.x = this.video.videoWidth / 2 - croppedSize.w / 2;
        croppedSize.y = this.video.videoHeight / 2 - croppedSize.h / 2;
      };

      var drawOnCanvas = () => {
        if (this.video.paused || this.video.ended) return false;

        // --- calculate start cropping coords
        if (canvas) {
          getCroppedSize();

          var w = croppedSize.w;
          var h = croppedSize.h;
          var x = croppedSize.x;
          var y = croppedSize.y;

          if (x < 0 || y < 0) {
            canvasContext.drawImage(this.video, 0, 0);
          } else {
            // --- crop video to canvas
            canvasContext.drawImage(
              this.video,
              x,
              y,
              w,
              h,
              0,
              0,
              canvas.width,
              canvas.height
            );
          }
        }
      };

      setupCropping();
      drawOnCanvas();

      // convert image stored in canvas to base64 encoded image
      var imageData = canvas.toDataURL("image/jpeg");
      this.stopCamera();

      resolve({
        photo: imageData,
        photoRaw: imageData
      });
    });
  };
})();

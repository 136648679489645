// --- ask service worker to check for update
function checkForUpdate(serviceWorker) {
  return new Promise(resolve => {
    const onSWMsg = event => {
      if (!event || !event.data || !event.data.type) return;
      if (event.data.type === "UPDATE_AVAILABLE") {
        serviceWorker.removeEventListener("message", onSWMsg);
        return resolve(true);
      }
    };
    serviceWorker.addEventListener("message", onSWMsg);

    serviceWorker.controller.postMessage({
      action: "CHECK_FOR_UPDATES"
    });
  });
}

// --- ask service worker to activate update
function activateUpdate(serviceWorker) {
  return new Promise(resolve => {
    const onSWMsg = event => {
      if (!event || !event.data || !event.data.type) return;
      if (event.data.type === "UPDATE_ACTIVATED") {
        serviceWorker.removeEventListener("message", onSWMsg);
        return resolve(true);
      }
    };
    serviceWorker.addEventListener("message", onSWMsg);

    serviceWorker.controller.postMessage({
      action: "ACTIVATE_UPDATE"
    });
  });
}

// --- check for updates and activate update (if available of service worker)
async function updateSW(serviceWorker) {
  await checkForUpdate(serviceWorker);
  await activateUpdate(serviceWorker);
}

// --- sleep for given milliseconds
function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), ms);
  });
}

// --- handle blank white page issue (due to cache)
async function onError(e) {
  // --- check if angular initial load scripts failed to load (which causes blank white page issue)
  var target = e.target || e.srcElement;
  if (target.tagName !== "SCRIPT") return;
  let angularBuildFilesRegex = /(main|vendor|scripts|styles|polyfills|polyfills-es5|runtime)\.([a-zA-Z-0-9]+)\.js$/gi;
  if (!angularBuildFilesRegex.test(target.src)) return;

  // --- trigger service worker update message
  if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
    // --- show loader
    try {
      document.getElementById("script-load-retry-loader").style.display =
        "flex";
    } catch (e) {}

    // --- update service worker and reload page
    try {
      let serviceWorker = navigator.serviceWorker;
      await Promise.race([updateSW(serviceWorker), sleep(10 * 1000)]);
      window.location.reload();
    } catch (e) {
      console.log("Error updating SW", e);
      window.location.reload();
    }
  }
}

window.addEventListener("error", e => onError(e), true);
